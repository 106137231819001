@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-[Tahoma];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to top,
    rgb(10, 25, 47),
    hsla(32, 100%, 50%, 0.8)
  ) !important;
}

.img-hover:hover {
  transform: scale(1.6);
}

.enav-btn {
  padding: 10px;
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
  font-size: 20px;
  cursor: pointer;
  width: 100%;

  transition: font-size 300ms;
}
.enav-btn:hover {
  color: orangered;
  font-size: 25px;
}
.download-div-odsc {
  background-image: url("./assets/odmv-shortcuts.png");
  background-size: 280px;
}
.download-div-odpt {
  background-image: url("./assets/game_center_rnd1.png");
  background-size: 280px;
}
